import { useContext } from "react";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { Spinner } from "../../Common/Spinner";
import { DataTable } from "../../Common/DataTable/DataTable";
import { useParams } from "react-router-dom";

export const AgendaTable = observer(
  ({
    onClickAdd,
    currentAgendaId,
    showWorkOrders,
    sortedAgendas,
  }: {
    onClickAdd: (currentAgendaId: number | null) => void;
    currentAgendaId: number | null;
    showWorkOrders: boolean;
    sortedAgendas: any[];
  }) => {
    const rootStore = useContext(RootContext);
    const stationStore = rootStore.stationStore;
    const workOrderStore = rootStore.workOrderStore;
    const tripAgendaStore = rootStore.tripAgendaStore;

    const { id } = useParams<{ id: string }>();

    const handleDelete = async (agendaId : Number) => {
      if (!window.confirm("Delete this agenda?")) return;
      await tripAgendaStore.deleteAgenda(Number(agendaId));
      if (id) await tripAgendaStore.fetchAgendaById(id);
    };

    if (stationStore.loading || workOrderStore.loading) return <Spinner />;

    return (
      <DataTable
        headers={[
          { key: "sn", label: "SN" },
          { key: "id", label: "ID" },
          { key: "prev_trip_agenda_id", label: "Prev" },
          { key: "station", label: "Station" },
          { key: "work_order", label: "Work Order" },
          { key: "duration", label: "Duration" },
          { key: "distance", label: "Distance" },
          {
            key: "add",
            label: (
              <span
                onClick={() => {
                  onClickAdd(null);
                }}
                style={{
                  color: `${
                    currentAgendaId === null && showWorkOrders
                      ? "#a1fc07"
                      : "white"
                  }`,
                  cursor: "pointer",
                }}
              >
                Add
              </span>
            ),
          },
          { key: "delete", label: "" },
        ]}
        data={sortedAgendas.map((agenda) => {
          return {
            ...agenda,
            add: agenda.id && (
              <button
                className={
                  currentAgendaId === +agenda.id ? "button selected" : "button"
                }
                onClick={() => onClickAdd(+agenda.id)}
              >
                Add
              </button>
            ),
            delete: agenda.id && (
              <button
                className="button delete"
                onClick={() => handleDelete(agenda.id)}
              >
                Del
              </button>
            ),
          };
        })}
      />
    );
  }
);
