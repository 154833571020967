import "./BooleanInput.css";

export const BooleanInput = ({
  checked,
  onToggle,
  label,
}: {
  checked: boolean;
  onToggle: () => void;
  label?: string;
}) => {
  return (
    <div className="toggle-container">
      {label && <label>{label}</label>}
      <label className="toggle-switch">
        <input
          type="checkbox"
          className="toggle-input"
          checked={checked}
          onChange={onToggle}
        />
        <span className="toggle-slider"></span>
      </label>
    </div>
  );
};
