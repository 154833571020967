import { ReactElement, ReactNode } from "react";
import "./DataTable.css";
import { FaPlus, FaPen, FaTrash } from "react-icons/fa";

interface DataTableProps {
  headers: Array<{ label: string | ReactElement; key: string }>;
  data: Array<any>;
  handleAdd?: () => void;
  handleEdit?: (id: number, data: any) => void;
  handleDelete?: (id: number) => void;
  title?: string;
}

export const DataTable = ({
  headers,
  data,
  handleAdd,
  handleEdit,
  handleDelete,
  title,
}: DataTableProps) => {
  return (
    <div className="data-table-container">
      {title && <h1>{title}</h1>}
      <table className="data-table">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header.label}</th>
            ))}
            <th>
              {handleAdd && (
                <span className="actions">
                  <FaPlus onClick={handleAdd} style={{ cursor: "pointer" }} />
                </span>
              )}
            </th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data
              //.filter((value) => value !== undefined && value !== null)
              .map((data, index) => !data || data === undefined ? null : (
                <tr key={index}>
                  {headers.map((header, i) => (
                    <td style={{ padding: "5px" }} key={i}>
                      {data[header.key]}
                    </td>
                  ))}
                  <td className="action-icons">
                    {handleEdit && (
                      <FaPen
                        style={{ cursor: "pointer" }}
                        onClick={() => handleEdit(data.id, data)}
                      />
                    )}
                    {handleDelete && (
                      <FaTrash
                        style={{ cursor: "pointer", paddingLeft: "5px" }}
                        onClick={() => {
                          handleDelete(data.id);
                        }}
                      />
                    )}
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};
