import { useState, useEffect } from "react";
import { Modal } from "../../Common/Modal/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { useParams, useNavigate } from "react-router-dom";

export const EditUserServer = observer(() => {
	const rootStore = useContext(RootContext);
	const serverStore = rootStore.serverStore;


	const [server, setServer] = useState("");
	const [serverUsername, setServerUsername] = useState("");
	const [serverPassword, setServerPassword] = useState("");

	const { id } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (!serverStore.user_servers.length) {
			serverStore.fetchUserServers();
		}
		const currentserver = serverStore.user_servers.find((server) => server.id === id);
		setServerUsername(currentserver?.server_username);
		setServer(currentserver?.domain_name);
	}, []);


	const editUserServer = async (e) => {
		e.preventDefault();
		if (!serverUsername || !serverPassword) {
			toast.error("Missing fields");
			return;
		}
		const result = serverStore.editUserServer(id, { server_username: serverUsername, server_password: serverPassword });
		if (result) navigate("/");
	};

	return (
		<>
			<Modal
				title={"Edit server credentials"}
				closeModal={() => {
					navigate("/");
				}}
			>
				<form
					className="Form"
					autoComplete="off"
				>
					<label>Server</label>
					<input
						type="text"
						value={server}
						readOnly
					/>

					<label>Server username</label>
					<input
						type="text"
						value={serverUsername}
						autoComplete="off"
						onChange={(e) => setServerUsername(e.target.value)}
					/>

					<label>Server password</label>
					<input
						type="text"
						value={serverPassword}
						autoComplete="off"
						onChange={(e) => setServerPassword(e.target.value)}
					/>
					<br />
					<button className="button" onClick={editUserServer}>Submit</button>
				</form>
			</Modal>
		</>
	);
});
