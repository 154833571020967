import { makeAutoObservable, runInAction } from "mobx";
import { callApi } from "../actions/apiMethods";
import { toast } from "react-toastify";

export class tripStationStore {
	stationsInTrip = [];
	allAddedStations = [];
	loading = false;

	constructor() {
		makeAutoObservable(this);
	}

	addStation = async (tripId, formEntries) => {
		const response = await callApi("POST", `api/trip/${tripId}/trip-Station`, formEntries);
		if (!response) return;
		runInAction(() => {
			this.stationsInTrip.push({ ...formEntries });
		});
		toast.success(response.message);
		return response.message;
	};

	fetchTripStationByTripId = async (tripId) => {
		this.loading = true;
		const response = await callApi("GET", `api/trip/${tripId}/trip-Station`);
		if (!response) return;
		runInAction(() => {
			this.stationsInTrip = response;
			this.loading = false;
		});
	};

	fetchAllTripStations = async () => {
		this.loading = true;
		const response = await callApi("GET", `api/trip-Station`);
		if (!response) return;
		runInAction(() => {
			this.allAddedStations = response;
			this.loading = false;
		});
	};

	deleteStation = async (tripStationId) => {
		const response = await callApi("DELETE", `api/trip-Station/${tripStationId}`);
		if (!response) return;
		runInAction(() => {
			this.stationsInTrip = this.stationsInTrip.filter((trip) => trip.station_id !== tripStationId);
		});
		toast.success(response.message);
	};

	editStation = async (tripStationId, updatedTripData) => {
		const response = await callApi("PUT", `api/trip-Station/${tripStationId}`, updatedTripData);
		if (!response) return;
		runInAction(() => {
			this.stationsInTrip = this.stationsInTrip.map((tripStation) => (tripStation.station_id === tripStationId ? { ...tripStation, ...updatedTripData } : tripStation));
		});
		toast.success(response.message);
		return response.message;
	};
}
