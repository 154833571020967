import { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { extractUniqueItems } from "../../helpers/extractUniqueItems";
import { TicketOptions } from "./TicketOptions";

export const TicketHome = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const rootStore = useContext(RootContext);
  const userStore = rootStore.userStore;
  const stationStore = rootStore.stationStore;
  const ticketStore = rootStore.ticketStore;
  const ticketActionStore = rootStore.ticketActionStore;
  const ticketReasonStore = rootStore.ticketReasonStore;
  const ticketStatusStore = rootStore.ticketStatusStore;
  const serverStore = rootStore.serverStore;

  useEffect(() => {
    if (!serverStore.loading && !serverStore.user_servers.length) serverStore.fetchUserServers();
    if (!ticketStore.loading && !ticketStore.items.length) ticketStore.fetchItems();
    if (!ticketStatusStore.loading && !ticketStatusStore.items.length) ticketStatusStore.fetchItems();
    if (!ticketReasonStore.loading && !ticketReasonStore.items.length) ticketReasonStore.fetchItems();
    if (!ticketActionStore.loading && !ticketActionStore.items.length) ticketActionStore.fetchItems();
    if (!userStore.loading && !userStore.items.length) userStore.fetchItems();
    if (!stationStore.loading && !stationStore.items.length) stationStore.fetchItems();
  }, []);

  return (
    <>
      <nav className="secondary-navbar">
        <ul>
          <li className="nav-li">
            <Link
              to="/ticket/tabular"
              className={
                location.pathname === "/ticket/tabular" ||
                  location.pathname === "/ticket"
                  ? "active"
                  : ""
              }
            >
              Tabular
            </Link>
          </li>
          <li className="nav-li">
            <Link
              to="/ticket/map"
              className={location.pathname === "/ticket/map" ? "active" : ""}
            >
              Map
            </Link>
          </li>
        </ul>
      </nav>

      <div className="map-options-grid">
        <Outlet />
        <TicketOptions />
      </div>
    </>
  );
});
