import ReactModal from "react-modal";
import "./Modal.css";
import { FaTimes } from "react-icons/fa";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "3px solid ##3c8dbc",
  },
};

export const Modal = ({
  loading,
  title,
  closeModal,
  children,
}: {
  loading?: boolean;
  title: string;
  closeModal: () => void;
  children: React.ReactNode;
}) => (
  <ReactModal
    isOpen={true}
    appElement={document.getElementById("root") as HTMLElement}
    // onAfterOpen={afterOpenModal}
    onRequestClose={closeModal}
    style={customStyles}
    contentLabel="Example Modal"
  >
    <div className="Modal">
      <div className="modalHeader">
        <div className="modalHeaderTitle">{title}</div>
        <FaTimes onClick={closeModal} style={{ cursor: "pointer" }} />
      </div>
      <div className="modalChildren">{children}</div>
    </div>
  </ReactModal>
);
