import { makeAutoObservable, runInAction } from "mobx";
import { getAll } from "../actions/apiMethods";
import { allStations } from "../types";

export class stationStore {
  items: Array<allStations> = [];
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchItems = async () => {
    this.loading = true;
    const response = await getAll("station");
    if (!response) return;
    runInAction(() => {
      this.items = response.message;
      this.loading = false;
    });
  };

  get keyItems() {
    let obj: any = {};
    this.items.forEach((item) => {
      let keyItem: any = {};
      item.stations.forEach(i => {
        keyItem[`key_${i.id}`] = i;
      })
      obj[`key_${item.server_id}`] = keyItem;
    });
    return obj;
  }

}
