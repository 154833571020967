import { makeAutoObservable, runInAction } from "mobx";
import { callApi } from "../actions/apiMethods";
import { toast } from "react-toastify";
import { tripAgenda } from "../types";

export class tripAgendaStore {
  agendas: Array<tripAgenda> = [];
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchAgenda = async () => {
    this.loading = true;
    const response = await callApi("GET", `trip/agenda`);
    if (!response) return;
    runInAction(() => {
      this.agendas = response.message;
      this.loading = false;
    });
  };

  fetchAgendaById = async (id:string) => {
    this.loading = true;
    const response = await callApi("GET", `trip/agenda/${id}`);
    if (!response) return;
    runInAction(() => {
      this.agendas = response.message;
      this.loading = false;
    });
  };

  addAgenda = async (agenda: {
    trip_id: number;
    prev_trip_agenda_id: number | null;
    station_id: number;
    work_order_id: number;
    server_id: number;
  }) => {
    const response = await callApi("POST", `trip/agenda`, agenda);
    if (!response) return;
    runInAction(() => {
      this.agendas.push({ ...agenda, id: response.message });
    });
    toast.success("Agenda added successfully");
    return response.message;
  };

  editAgenda = async (agendaId: string, updatedAgenda: any) => {
    const response = await callApi(
      "PUT",
      `trip/agenda/${agendaId}`,
      updatedAgenda
    );
    if (!response) return;
    runInAction(() => {
      this.agendas = this.agendas.map((agenda) =>
        agenda.id === response.message.id ? response.message : agenda
      );
    });
    toast.success(response.message);
    return response.message;
  };

  deleteAgenda = async (agendaId: number) => {
    const response = await callApi("DELETE", `trip/agenda/${agendaId}`);
    if (!response) return;
    toast.success(response.message);
  };

  fetchTripAgenda = async (tripId: string) => {
    this.loading = true;
    const response = await callApi("GET", `trip/${tripId}/agenda`);
    if (!response) return;
    return response.message;
  };
}
