import { useState, useContext, useEffect } from "react";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Spinner } from "../../Common/Spinner";
import { WorkOrderOptions } from "../WorkOrder/WorkOrderOptions";
import { AgendaAddMap } from "./AgendaAddMap";
import { AgendaTable } from "./AgendaTable";
import { toJS } from "mobx";
import { tripAgenda } from "../../types";
import { callApi } from "../../actions/apiMethods";

export const TripDetail = observer(() => {
  const rootStore = useContext(RootContext);
  const tripStationStore = rootStore.tripStationStore;
  const stationStore = rootStore.stationStore;
  const workOrderStore = rootStore.workOrderStore;
  const workOrderStatusStore = rootStore.workOrderStatusStore;
  const workOrderPriorityStore = rootStore.workOrderPriorityStore;
  const workOrderMaintenanceTypeStore = rootStore.workOrderMaintenanceTypeStore;
  const userStore = rootStore.userStore;

  const tripAgendaStore = rootStore.tripAgendaStore;

  const [showWorkOrders, setShowWorkOrders] = useState(false);
  const [currentAgendaId, setCurrentAgendaId] = useState<number | null>(null);


  const { id } = useParams<{ id: string }>();


  useEffect(() => {
    if (!workOrderStore.items.length) workOrderStore.fetchItems();
    if (!workOrderStatusStore.items.length) workOrderStatusStore.fetchItems();
    if (!workOrderPriorityStore.items.length)
      workOrderPriorityStore.fetchItems();
    if (!workOrderMaintenanceTypeStore.items.length)
      workOrderMaintenanceTypeStore.fetchItems();
    if (!userStore.items.length) userStore.fetchItems();
    if (!stationStore.items.length) stationStore.fetchItems();
    if (!tripAgendaStore.agendas.length && id != undefined) tripAgendaStore.fetchAgendaById(id);
  }, [id]);

  const onClickAdd = (currentAgendaId: number | null) => {
    setShowWorkOrders(true);
    setCurrentAgendaId(currentAgendaId);
  };

  const [sorted, setSorted] = useState<any[]>([]);

  useEffect(() => {
    const agendas = tripAgendaStore.agendas.filter(
      (agenda) => agenda.trip_id === +id!
    );
    const actualSort = async () => {
      let tempSorted: any[] = [];
      async function sortAgendas(
        agendas: Array<tripAgenda>,
        parentId: number | null
      ) {
        const agendaToAdd = tripAgendaStore.agendas
          .filter((agenda) => agenda.trip_id === +id!)
          .find((agenda) => agenda.prev_trip_agenda_id === parentId);
        if (tempSorted.filter((a) => a.sn).length === agendas.length) {
          return tempSorted;
        }
        if (agendaToAdd) {
          const stationServer = stationStore.items.find(
            (serverStations) =>
              +serverStations.server_id === +agendaToAdd.server_id
          );
          const station = stationServer?.stations.find(
            (station) => +station.id === +agendaToAdd.station_id
          );
          const workOrderServer = workOrderStore.items.find(
            (serverWorkOrders) =>
              +serverWorkOrders.server_id === +agendaToAdd.server_id
          );
          const workorder = workOrderServer?.workOrders.find(
            (workOrder) => +workOrder.id === +agendaToAdd.work_order_id
          );

          const parentAgenda = tempSorted.find(
            (agenda) => +agenda.id === parentId
          );
          if (parentAgenda && parentAgenda.latitude && parentAgenda.longitude) {
            const response = await callApi("POST", `google-distance`, {
              origin: `${parentAgenda.latitude},${parentAgenda.longitude}`,
              destination: `${station?.latitude},${station?.longitude}`,
            });
            let duration = "-";
            let distance = "-";
            if (response.status === "OK") {
              duration = response?.rows[0]?.elements[0]?.duration?.text;
              distance = response?.rows[0]?.elements[0]?.distance?.text;
            }
            tempSorted.push({
              station: `${parentAgenda?.station} - ${station?.name}`,
              duration: duration,
              distance: distance,
            });
          }
          tempSorted.push({
            id: agendaToAdd.id,
            sn: parentAgenda ? parentAgenda.sn + 1 : 1,
            prev_trip_agenda_id: agendaToAdd.prev_trip_agenda_id,
            station: station?.name,
            latitude: station?.latitude,
            longitude: station?.longitude,
            work_order: workorder?.title,
            work_order_id: agendaToAdd.work_order_id,
            server_id: agendaToAdd.server_id,
          });
          await sortAgendas(agendas, +agendaToAdd.id);
        }
      }
      await sortAgendas(agendas, null);
      return tempSorted;
    };
    actualSort().then((tempSorted) => {
      setSorted(tempSorted);
    });
  }, [stationStore.items, workOrderStore.items, tripAgendaStore.agendas]);

  if (
    tripStationStore.loading ||
    stationStore.loading ||
    workOrderStore.loading ||
    tripAgendaStore.loading
  )
    return <Spinner />;

  return (
    <div className="margin-left">
      <>
        <h2>TRIP {`TN${String(id).padStart(5, "0")}`}</h2>
        <div className="map-options-grid">
          <AgendaAddMap
            currentAgendaId={currentAgendaId}
            showWorkOrders={showWorkOrders}
            sortedAgendas={sorted}
          />
          <div>
            <AgendaTable
              currentAgendaId={currentAgendaId}
              showWorkOrders={showWorkOrders}
              onClickAdd={onClickAdd}
              sortedAgendas={sorted}
            />
            {showWorkOrders && (
              <button
                className="button"
                onClick={() => {
                  setShowWorkOrders(false);
                  setCurrentAgendaId(null);
                }}
              >
                {"Hide workorders"}
              </button>
            )}
            {showWorkOrders && <WorkOrderOptions />}
          </div>
        </div>
      </>
    </div>
  );
});
