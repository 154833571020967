import { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { getFromLocalStorage } from "../../localStorage";
import { Modal } from "../../Common/Modal/Modal";
import Select from "react-select";
import { toast } from "react-toastify";

export const AddFollower = observer(
  ({ closeModal }: { closeModal: () => void }) => {
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const workOrderStore = rootStore.workOrderStore;
    const serverStore = rootStore.serverStore;

    const { id } = useParams();

    const [follower, setFollower] = useState<{
      label: "string";
      value: "string";
    }>();

    const userOptions = userStore.items.find(
      (item) => item.server === workOrderStore.selectedServer
    ).users;

    const userServer = serverStore.user_servers.find(
      (server) => server.domain_name === workOrderStore.selectedServer
    );
    const user = userOptions.find(
      (user: any) => user.username === userServer!.server_username
    );

    const addFollower = async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (!user) {
        toast.error(`Not logged in to server ${workOrderStore.selectedServer}`);
        return;
      }
      if (!follower) {
        toast.error("Follower not selected");
        return;
      }
      const token = getFromLocalStorage(workOrderStore.selectedServer);
      fetch(`${workOrderStore.selectedServer}/mms/work_order/${id}/follower`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: follower?.value,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          closeModal();
        })
        .catch((e) => {
          toast.error("Error adding follower");
          console.log(e);
        });
    };
    return (
      <>
        <Modal title="Add Follower" closeModal={() => closeModal()}>
          <form className="Form" autoComplete="off">
            <Select
              className="form-select"
              placeholder="follower"
              value={follower}
              onChange={(e: any) => setFollower(e)}
              options={userOptions.map((user: any) => {
                return {
                  label: user.name,
                  value: user.id,
                };
              })}
            />
            <br />
            <br />
            <br />
            <br />
            <button className="button" onClick={(e) => addFollower(e)}>
              Save
            </button>
          </form>
        </Modal>
      </>
    );
  }
);
