import { BASE_URL } from "../config";
import { getFromLocalStorage, token } from "../localStorage";
import { toast } from "react-toastify";

export const callApi = async (
  method: string,
  endpoint: string,
  body?: Object | undefined
) => {
  try {
    const fullUrl = `${BASE_URL}/${endpoint}`;
    const options = {
      method,
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        Authorization: `Bearer ${token.get()}`,
      },
    };
    if (body) Object.assign(options, { body: JSON.stringify(body) });
    const response = await fetch(fullUrl, options);
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }
    return data;
  } catch (e: any) {
    toast.error(e.message || "Error connecting to server");
    return;
  }
};

export const get = (endpoint: string) => callApi("GET", endpoint);
export const post = (endpoint: string, body: Object) =>
  callApi("POST", endpoint, body);
export const put = (endpoint: string, body: Object) =>
  callApi("PUT", endpoint, body);
export const del = (endpoint: string, body: Object) =>
  callApi("DELETE", endpoint, body);

export const getAll = (endpoint: string) => {
  const allTokens = getFromLocalStorage("all_tokens") as string;
  if (!allTokens) {
    toast.error("Not logged in to any server");
    return;
  }
  return callApi("POST", endpoint, { tokens: JSON.parse(allTokens) });
};
