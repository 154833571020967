import { makeAutoObservable, runInAction } from "mobx";
import { getAll } from "../actions/apiMethods";
import { extractUniqueItems } from "../helpers/extractUniqueItems";

export class userStore {
	items = [];
	loading = false;

	constructor() {
		makeAutoObservable(this);
	}

	fetchItems = async () => {
		this.loading = true;
		const response = await getAll("allUsers");
		if (!response) return;
		runInAction(() => {
			this.items = response.message;
			this.loading = false;
		});
	};

  get keyItems() {
    let obj = {};
    this.items.forEach((item) => {
      let keyItem = {};
      item.users.forEach(i => {
        keyItem[`key_${i.id}`] = i;
      })
      obj[`key_${item.server_id}`] = keyItem;
    });
    return obj;
  }

	get uniqueItems () {
    return extractUniqueItems(this.items, "users", "email");
  }
}
