import { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { BooleanInput } from "../../Common/Inputs/BooleanInput";
import { extractUniqueItems } from "../../helpers/extractUniqueItems";
import { FaCircle } from "react-icons/fa";

export const TicketOptions = observer(() => {
  const rootStore = useContext(RootContext);
  const userStore = rootStore.userStore;
  const stationStore = rootStore.stationStore;
  const ticketStore = rootStore.ticketStore;
  const ticketActionStore = rootStore.ticketActionStore;
  const ticketReasonStore = rootStore.ticketReasonStore;
  const ticketStatusStore = rootStore.ticketStatusStore;
  const serverStore = rootStore.serverStore;



  useEffect(() => {
    if (!ticketStore.selectedServers.length)
      ticketStore.setSelectedServers(
        serverStore.user_servers
          .filter((server) =>
            Object.keys(localStorage).includes(server.domain_name)
          )
          .map((server) => {
            return {
              label: server.domain_name,
              value: server.domain_name,
            };
          })
      );
  }, [serverStore.user_servers]);

  return (
    <div>
      <div>
        <Select
          isMulti
          className="form-select"
          placeholder="Servers"
          value={ticketStore.selectedServers}
          onChange={(selectedoptions: any) => {
            ticketStore.setSelectedServers(selectedoptions);
          }}
          options={serverStore.user_servers
            .filter((server) =>
              Object.keys(localStorage).includes(server.domain_name)
            )
            .map((server) => {
              return {
                label: server.domain_name,
                value: server.domain_name,
              };
            })}
        />
        <BooleanInput
          label="Show Archived"
          checked={ticketStore.showArchivedTickets}
          onToggle={ticketStore.setShowArchivedTickets}
        />
      </div>
      <div className="options">
        <div className="options-header">
          <ul>
            {ticketStore.viewByOptions.map((option) => {
              return (
                <li
                  key={option.id}
                  onClick={() => {
                    ticketStore.setSelectedFilter(option.value);
                    ticketStore.setSelectedFilterValue("");
                  }}
                  className={
                    ticketStore.selectedFilter === option.value ? "active" : ""
                  }
                >
                  {option.label}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="options-body">
          {ticketStore.selectedFilter === "status" && (
            <table className="options-table">
              <tbody>
                {ticketStatusStore.uniqueItems.map((item) => (
                  <tr
                    key={item.caption}
                    onClick={() =>
                      ticketStore.selectedFilterValue === item.caption
                        ? ticketStore.setSelectedFilterValue("")
                        : ticketStore.setSelectedFilterValue(item.caption)
                    }
                    className={
                      ticketStore.selectedFilterValue === item.caption
                        ? "active"
                        : ""
                    }
                  >
                    <td>{item.caption}</td>
                    <td>
                      <FaCircle style={{ color: item.color }} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {ticketStore.selectedFilter === "reason" && (
            <table className="options-table">
              <tbody>
                {ticketReasonStore.uniqueItems.map((item) => (
                  <tr
                    key={item.caption}
                    onClick={() =>
                      ticketStore.selectedFilterValue === item.caption
                        ? ticketStore.setSelectedFilterValue("")
                        : ticketStore.setSelectedFilterValue(item.caption)
                    }
                    className={
                      ticketStore.selectedFilterValue === item.caption
                        ? "active"
                        : ""
                    }
                  >
                    <td>{item.caption}</td>
                    <td>
                      <FaCircle style={{ color: item.color }} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {ticketStore.selectedFilter === "action" && (
            <table className="options-table">
              <tbody>
                {ticketActionStore.uniqueItems.map((item) => (
                  <tr
                    key={item.caption}
                    onClick={() =>
                      ticketStore.selectedFilterValue === item.caption
                        ? ticketStore.setSelectedFilterValue("")
                        : ticketStore.setSelectedFilterValue(item.caption)
                    }
                    className={
                      ticketStore.selectedFilterValue === item.caption
                        ? "active"
                        : ""
                    }
                  >
                    <td>{item.caption}</td>
                    <td>
                      <FaCircle style={{ color: item.color }} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {ticketStore.selectedFilter === "assigned" && (
            <table className="options-table">
              <tbody>
                {userStore.uniqueItems
                  .map((item) => !item.email ? null : (
                    <tr
                      key={item.email}
                      onClick={() =>
                        ticketStore.selectedFilterValue === item.email
                          ? ticketStore.setSelectedFilterValue("")
                          : ticketStore.setSelectedFilterValue(item.email)
                      }
                      className={
                        ticketStore.selectedFilterValue === item.email
                          ? "active"
                          : ""
                      }
                    >
                      <td>{item.email}</td>
                      <td>
                        <FaCircle style={{ color: item.color }} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
});
