import { redirect, createHashRouter } from "react-router-dom";
import App from "./App";
import { Home } from "./Home";
import { Login } from "./Components/Login";
import { Signup } from "./Components/Signup";
import { checkRedirectToLogin, isLoggedIn } from "./helpers/isLoggedIn";
import { TripMap } from "./Components/TripDetail/TripMap";
import { EditUserServer } from "./Components/Server/EditUserServer";
import { EditServer } from "./Components/Server/EditServer";
import { TicketHome } from "./Components/Ticket/TicketHome";
import { TicketTabular } from "./Components/Ticket/TicketTabular";
import { TicketMap } from "./Components/Ticket/TicketMap";
import { WorkOrderHome } from "./Components/WorkOrder/WorkOrderHome";
import { WorkOrderTabular } from "./Components/WorkOrder/WorkOrderTabular";
import { WorkOrderMap } from "./Components/WorkOrder/WorkOrderMap";
import { TripTable } from "./Components/Trip/TripTable";
import { TripAdd } from "./Components/Trip/TripAdd";
import { TripEdit } from "./Components/Trip/TripEdit";
import { TripDetail } from "./Components/TripDetail/TripDetail";
import { WorkOrderDetail } from "./Components/WorkOrder/WorkOrderDetail";

export const router = createHashRouter([
  {
    element: <App />,
    loader: checkRedirectToLogin,
    children: [
      {
        path: "/",
        element: <Home />,
        children: [
          {
            path: "/user-server/:id",
            element: <EditUserServer />,
          },
          {
            path: "/server/:id",
            element: <EditServer />,
          },
        ],
      },
      {
        path: "/trip",
        element: <TripTable />,
        children: [
          {
            path: "/trip/add",
            element: <TripAdd />,
          },
          {
            path: "/trip/:id",
            element: <TripEdit />,
          },
        ],
      },
      {
        path: "/trip-detail/:id/",
        element: <TripDetail />,
      },
      {
        path: "/ticket",
        element: <TicketHome />,
        children: [
          {
            index: true,
            element: <TicketTabular />,
          },
          {
            path: "/ticket/tabular",
            element: <TicketTabular />,
          },
          {
            path: "/ticket/map",
            element: <TicketMap />,
          },
        ],
      },
      {
        path: "/workorder",
        element: <WorkOrderHome />,
        children: [
          {
            index: true,
            element: <WorkOrderTabular />,
          },
          {
            path: "/workorder/tabular",
            element: <WorkOrderTabular />,
          },
          {
            path: "/workorder/map",
            element: <WorkOrderMap />,
          },
        ],
      },
      {
        path: "workorder_detail/:id",
        element: <WorkOrderDetail />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
    loader: () => {
      if (isLoggedIn()) return redirect("/");
      return null;
    },
  },
  {
    path: "/signup",
    element: <Signup />,
    loader: () => {
      if (isLoggedIn()) return redirect("/");
      return null;
    },
  },
]);
