import { useEffect, useContext } from "react";
import { Link, useLocation, Outlet } from "react-router-dom";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { WorkOrderOptions } from "./WorkOrderOptions";

export const WorkOrderHome = observer(() => {
  const location = useLocation();
  const rootStore = useContext(RootContext);
  const workOrderStore = rootStore.workOrderStore;
  const workOrderStatusStore = rootStore.workOrderStatusStore;
  const workOrderPriorityStore = rootStore.workOrderPriorityStore;
  const workOrderMaintenanceTypeStore = rootStore.workOrderMaintenanceTypeStore;
  const userStore = rootStore.userStore;
  const stationStore = rootStore.stationStore;

  useEffect(() => {
    if (!workOrderStore.items.length && !workOrderStore.loading) workOrderStore.fetchItems();
    if (!workOrderStatusStore.items.length && !workOrderStatusStore.loading) workOrderStatusStore.fetchItems();
    if (!workOrderPriorityStore.items.length && !workOrderPriorityStore.loading) workOrderPriorityStore.fetchItems();
    if (!workOrderMaintenanceTypeStore.items.length && !workOrderMaintenanceTypeStore.loading) workOrderMaintenanceTypeStore.fetchItems();
    if (!userStore.items.length && !userStore.loading) userStore.fetchItems();
    if (!stationStore.items.length && stationStore.loading) stationStore.fetchItems();
  }, []);

  return (
    <>
      <nav className="secondary-navbar">
        <ul>
          <li className="nav-li">
            <Link
              to="/workorder/tabular"
              className={
                location.pathname === "/workorder/tabular" ||
                  location.pathname === "/workorder"
                  ? "active"
                  : ""
              }
            >
              Tabular
            </Link>
          </li>
          <li className="nav-li">
            <Link
              to="/workorder/map"
              className={location.pathname === "/workorder/map" ? "active" : ""}
            >
              Map
            </Link>
          </li>
        </ul>
      </nav>

      <div className="map-options-grid">
        <Outlet />
        <WorkOrderOptions />
      </div>
    </>
  );
});
