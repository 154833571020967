import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { DataTable } from "../../Common/DataTable/DataTable";
import { Spinner } from "../../Common/Spinner";
import { toJS } from "mobx";
import { Link, Outlet, useNavigate } from "react-router-dom";

export const TripTable = observer(() => {
  const navigate = useNavigate();

  const rootStore = useContext(RootContext);
  const tripStore = rootStore.tripStore;
  const mmsUserStore = rootStore.mmsUserStore;

  useEffect(() => {
    if (!tripStore.items.length) tripStore.fetchItems();
    if (!mmsUserStore.items.length) mmsUserStore.fetchItems();
  }, []);

  const deleteTrip = (id: number) => {
    if (window.confirm("Delete this trip?")) tripStore.deleteTrip(id);
  };

  if (tripStore.loading) return <Spinner />;

  return (
    <>
      <div>
        <DataTable
          data={tripStore.items
            .slice()
            .sort((a, b) => a.id - b.id)
            .map((trip, index) => {
              return {
                id: +trip.id,
                trip_no: `TN${String(trip.id).padStart(5, "0")}`,
                view: (
                  <i>
                    <Link to={`/trip-detail/${trip.id}`}>View</Link>
                  </i>
                ),
                description: trip.description,
                planned_by: mmsUserStore.items.find(
                  (item) => +item.id === +trip.planned_by
                )?.username,
                start_date: trip.start_date?.split("T")[0],
                end_date: trip.end_date?.split("T")[0],
              };
            })}
          headers={[
            { key: "trip_no", label: "Trip Number" },
            { key: "description", label: "Description" },
            { key: "start_date", label: "Start Date" },
            { key: "end_date", label: "End Date" },
            { key: "planned_by", label: "Planned By" },
            { key: "view", label: "View" },
          ]}
          handleAdd={() => navigate("/trip/add")}
          handleDelete={(id) => deleteTrip(id)}
          handleEdit={(id) => navigate(`/trip/${id}`)}
        />
      </div>
      <Outlet />
    </>
  );
});
