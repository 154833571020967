import { useState, useContext } from "react";
import { toast } from "react-toastify";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { Modal } from "../../Common/Modal/Modal";
import { useNavigate } from "react-router-dom";
import { DateInput } from "../../Common/Inputs/DateInput";

export const TripAdd = observer(() => {
  const navigate = useNavigate();

  const rootStore = useContext(RootContext);
  const tripStore = rootStore.tripStore;

  const [editformState, setEditFormState] = useState({
    description: "",
    start_date: "",
    end_date: "",
  });

  const handleSubmitForEdit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!editformState.description) {
      toast.error("Missing fields");
      return;
    }
    const result = await tripStore.addTrip({
      description: editformState.description,
      ...(editformState.start_date && { start_date: editformState.start_date }),
      ...(editformState.end_date && { end_date: editformState.end_date }),
    });
    if (result) navigate("/trip");
  };

  const setNewFormForEdit = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setEditFormState((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      <Modal
        title={"Add trip"}
        closeModal={() => {
          navigate("/trip");
        }}
      >
        <form className="Form">
          <label>Description *</label>
          <input
            type="text"
            name="description"
            value={editformState.description}
            onChange={setNewFormForEdit}
          />
          <DateInput
            label="Start Date"
            name="start_date"
            value={editformState.start_date}
            onChange={setNewFormForEdit}
          />
          <DateInput
            label="End Date"
            name="end_date"
            value={editformState.end_date}
            onChange={setNewFormForEdit}
          />

          <br />
          <button className="button" onClick={handleSubmitForEdit}>
            {"Add"}
          </button>
        </form>
      </Modal>
    </div>
  );
});
