import "./navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { token } from "../../localStorage";
import { jwtDecode } from "jwt-decode";
import { decodedToken } from "../../types";

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();

  const localToken = token.get();
  const decoded = localToken ? (jwtDecode(localToken) as decodedToken) : null;

  const logout = () => {
    if (!window.confirm("Are you sure you want to logout?")) return;
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  return (
    <>
      <nav className="navbar">
        <ul>
          <li className="nav-li">
            <Link to="/" className={location.pathname === "/" ? "active" : ""}>
              Home
            </Link>
          </li>
          <li className="nav-li">
            <Link
              to="/trip"
              className={location.pathname.includes("/trip") ? "active" : ""}
            >
              Trip
            </Link>
          </li>
          <li className="nav-li">
            <Link
              to="/ticket"
              className={location.pathname.includes("/ticket") ? "active" : ""}
            >
              Ticket
            </Link>
          </li>
          <li className="nav-li">
            <Link
              to="/workorder"
              className={
                location.pathname.includes("/workorder") ? "active" : ""
              }
            >
              Work Order
            </Link>
          </li>
          <li
            className="user-icon nav-li"
            onClick={() =>
              document
                .getElementById("main-dropdown")
                ?.classList.toggle("visible")
            }
          >
            <FaUser color="white" />
            <div id="main-dropdown" className="dropdown">
              <ul>
                <li>{decoded?.user}</li>
                <li onClick={() => logout()}>Logout</li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
