import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { DataTable } from "../../Common/DataTable/DataTable";
import { Spinner } from "../../Common/Spinner";
import { useParams } from "react-router-dom";
import { getFromLocalStorage } from "../../localStorage";
import { toJS } from "mobx";
import { AddLog } from "./AddLog";
import { AddTask } from "./AddTask";
import { AddFollower } from "./AddFollower";
import { toast } from "react-toastify";

export const WorkOrderDetail = observer(() => {
  const rootStore = useContext(RootContext);
  const workOrderStore = rootStore.workOrderStore;
  const workOrderStatusStore = rootStore.workOrderStatusStore;
  const workOrderPriorityStore = rootStore.workOrderPriorityStore;
  const workOrderMaintenanceTypeStore = rootStore.workOrderMaintenanceTypeStore;
  const userStore = rootStore.userStore;
  const stationStore = rootStore.stationStore;
  const inventoryItemStore = rootStore.inventoryItemStore;
  const inventoryProductStore = rootStore.inventoryProductStore;

  const { id } = useParams();

  const [details, setDetails] = useState<any>();
  const [selectedTab, setSelectedTab] = useState("Logs");
  const [showAddLog, setShowAddLog] = useState(false);
  const [showAddTask, setShowAddTask] = useState(false);
  const [showAddFollower, setShowAddFollower] = useState(false);

  useEffect(() => {
    if (!workOrderStore.items.length) workOrderStore.fetchItems();
    if (!workOrderStatusStore.items.length) workOrderStatusStore.fetchItems();
    if (!workOrderPriorityStore.items.length)
      workOrderPriorityStore.fetchItems();
    if (!workOrderMaintenanceTypeStore.items.length)
      workOrderMaintenanceTypeStore.fetchItems();
    if (!userStore.items.length) userStore.fetchItems();
    if (!stationStore.items.length) stationStore.fetchItems();
    if (!inventoryItemStore.items.length) inventoryItemStore.fetchItems();
    if (!inventoryProductStore.items.length) inventoryProductStore.fetchItems();
    getAndSetDetails();
  }, []);

  const handleTaskDelete = (taskId: number) => {
    if (!window.confirm("Delete this task?")) return;
    fetch(
      `${workOrderStore.selectedServer}/mms/work_order/${id}/tasks/${taskId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${getFromLocalStorage(
            workOrderStore.selectedServer
          )}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        getAndSetDetails();
      })
      .catch((e) => {
        toast.error("Error deleting task");
        console.error(e);
      });
  };

  const handleFollowerDelete = (followerId: number) => {
    if (!window.confirm("Delete this follower")) return;
    fetch(
      `${workOrderStore.selectedServer}/mms/work_order/${id}/follower/${followerId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${getFromLocalStorage(
            workOrderStore.selectedServer
          )}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        getAndSetDetails();
      })
      .catch((e) => {
        toast.error("Error deleting follower");
        console.error(e);
      });
  };

  function getAndSetDetails() {
    const token = getFromLocalStorage(workOrderStore.selectedServer);
    fetch(`${workOrderStore.selectedServer}/mms/work_order/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setDetails(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  if (
    workOrderStore.loading ||
    workOrderStatusStore.loading ||
    workOrderPriorityStore.loading ||
    workOrderMaintenanceTypeStore.loading ||
    userStore.loading ||
    stationStore.loading
  )
    return <Spinner />;

  if (!details) return <Spinner />;

  const created_by = userStore.items
    ?.find((item) => item.server === workOrderStore.selectedServer)
    ?.users?.find((user: any) => +user.id === +details.created_by);
  const assigned_to = userStore.items
    ?.find((item) => item.server === workOrderStore.selectedServer)
    ?.users?.find((user: any) => +user.id === +details.assigned_to);
  const status = workOrderStatusStore.items
    ?.find((item) => item.server === workOrderStore.selectedServer)
    ?.data?.find((status) => status.id === +details.status);
  const priority = workOrderPriorityStore.items
    ?.find((item) => item.server === workOrderStore.selectedServer)
    ?.data?.find((priority) => priority.id === +details.priority);
  const maintenance_type = workOrderMaintenanceTypeStore.items
    ?.find((item) => item.server === workOrderStore.selectedServer)
    ?.data?.find(
      (maintenance_type) => maintenance_type.id === +details.maintenance_type
    );

  const inventoryItems = details?.logs?.map((log: any) => {
    const inventoryItem = inventoryItemStore.items
      ?.find((item) => item.server === workOrderStore.selectedServer)
      ?.items?.find((item: any) => +item.id === +log.item_id);

    const inventoryProduct = inventoryProductStore.items
      ?.find((item) => item.server === workOrderStore.selectedServer)
      ?.items.find(
        (product: any) => +product.id === +inventoryItem?.product_id
      );

    return {
      id: inventoryItem?.id,
      name: `${inventoryProduct?.pcategory_name || ""} - ${
        inventoryItem?.serial_no || ""
      }`,
    };
  });
  const stations = details?.station?.map((stationDetail: any) => {
    return stationStore.items
      ?.find((item) => item.server === workOrderStore.selectedServer)
      ?.stations?.find((station) => station.id === +stationDetail.id);
  });

  const followers = details.followers.map((follower: any) => {
    return userStore.items
      .find((item) => item.server === workOrderStore.selectedServer)!
      .users.find((user: any) => +user.id === +follower.user_id);
  });

  return (
    <>
      <div className="detail-div-info">
        <div>
          <p>Work Order Number: {id}</p>
          <p>Work Order Title: {details.title}</p>
          <p>Created By: {created_by.name}</p>
          <p>Assigned To: {assigned_to.name}</p>
        </div>
        <div>
          <p>Status : {status?.caption}</p>
          <p>Priority: {priority?.caption}</p>
          <p>Maintenance Type: {maintenance_type?.caption}</p>
          <p>Creation Date: {details.creation_date}</p>
        </div>
        <div>
          <p>Scheduled date : {details.scheduled_date}</p>
          <p>Completion Deadline: {details.completion_deadline}</p>
          {stations?.map((station: any) => (
            <p key={station.id}>Station: {station.name}</p>
          ))}
          <DataTable
            headers={[{ label: "Followers", key: "followers" }]}
            data={followers.map((follower: any) => ({
              followers: follower.name,
              id: follower.id,
            }))}
            handleAdd={() => setShowAddFollower(true)}
            handleDelete={handleFollowerDelete}
          />
        </div>
      </div>

      <div className="TabBar TabBarRounded" style={{ marginLeft: "10px" }}>
        <ul>
          <li
            className={selectedTab === "Logs" ? "active" : ""}
            onClick={() => setSelectedTab("Logs")}
          >
            <a>Logs</a>
          </li>
          <li
            className={selectedTab === "Tasks" ? "active" : ""}
            onClick={() => setSelectedTab("Tasks")}
          >
            <a>Tasks</a>
          </li>
        </ul>
      </div>

      {showAddLog && (
        <AddLog
          closeModal={() => {
            setShowAddLog(false);
            getAndSetDetails();
          }}
        />
      )}

      {showAddTask && (
        <AddTask
          closeModal={() => {
            setShowAddTask(false);
            getAndSetDetails();
          }}
        />
      )}

      {showAddFollower && (
        <AddFollower
          closeModal={() => {
            setShowAddFollower(false);
            getAndSetDetails();
          }}
        />
      )}

      {selectedTab === "Logs" ? (
        <div className="detail-div-logs">
          <button className="basic-button" onClick={() => setShowAddLog(true)}>
            Log note
          </button>
          {details.logs
            ?.slice()
            .sort((a: any, b: any) => b.id - a.id)
            .map((log: any, index: number) => (
              <div className="log">
                <b>
                  {
                    userStore.items
                      .find(
                        (item) => item.server === workOrderStore.selectedServer
                      )
                      .users.find((user: any) => +user.id === +log.user_id).name
                  }
                </b>
                <span> - {log.log_date} </span>

                <p style={{ marginLeft: "20px" }}>{log.text}</p>

                <div style={{ display: "flex", gap: "10px" }}>
                  <p>
                    <b>Assigned to :</b>{" "}
                    {
                      userStore.items
                        ?.find(
                          (item) =>
                            item.server === workOrderStore.selectedServer
                        )
                        ?.users?.find(
                          (user: any) => +user.id === +log.assigned_to
                        )?.name
                    }
                  </p>
                  <p>
                    <b>Item:</b> {inventoryItems[index]?.name}
                  </p>
                  <p>
                    <b>Actual man hours:</b> {log.actual_man_hours}
                  </p>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <DataTable
          headers={[
            { label: "ID", key: "id" },
            { label: "Description", key: "description" },
            { label: "Item", key: "item" },
            { label: "Assigned To", key: "assigned_to" },
            { label: "Estimated man hours", key: "estimated_man_hours" },
            { label: "Hours Spent", key: "hours_spent" },
            { label: "Task Result", key: "task_result" },
            { label: "Task Status", key: "task_status" },
          ]}
          data={details.tasks?.map((task: any) => ({
            ...task,
            assigned_to: userStore.items
              ?.find((item) => item.server === workOrderStore.selectedServer)
              ?.users?.find((user: any) => +user.id === +task.assigned_to)
              ?.name,
            item: inventoryItems?.find((item: any) => +item.id === task.item_id)
              ?.name,
          }))}
          handleAdd={() => setShowAddTask(true)}
          handleDelete={handleTaskDelete}
        />
      )}
    </>
  );
});
