export const extractUniqueItems = (storeItems, property = "data", compare = "caption") => {
	const uniqueItems = [];
	storeItems.forEach((item) => {
		item[property].forEach((subItem) => {
			const existingItem = uniqueItems.find((uniqueItem) => uniqueItem[compare] === subItem[compare]);
			if (!existingItem) {
				uniqueItems.push(subItem);
			}
		});
	});
	return uniqueItems;
};
