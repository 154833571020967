import { useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { Spinner } from "../../Common/Spinner";
import { MapContainer, TileLayer, Popup, CircleMarker } from "react-leaflet";
import { useParams } from "react-router-dom";
import { NumberedMarker } from "../../Common/NumberedMarker";

interface AgendaAddProps {
  showWorkOrders?: boolean;
  currentAgendaId: number | null;
  sortedAgendas: any[];
}

export const AgendaAddMap = observer(
  ({ showWorkOrders, currentAgendaId, sortedAgendas }: AgendaAddProps) => {
    const rootStore = useContext(RootContext);
    const workOrderStore = rootStore.workOrderStore;
    const workOrderStatusStore = rootStore.workOrderStatusStore;
    const workOrderPriorityStore = rootStore.workOrderPriorityStore;
    const workOrderMaintenanceTypeStore =
      rootStore.workOrderMaintenanceTypeStore;
    const userStore = rootStore.userStore;
    const stationStore = rootStore.stationStore;
    const tripAgendaStore = rootStore.tripAgendaStore;

    const { id } = useParams();

    if (
      workOrderStore.loading ||
      workOrderStatusStore.loading ||
      workOrderPriorityStore.loading ||
      workOrderMaintenanceTypeStore.loading ||
      userStore.loading ||
      stationStore.loading ||
      tripAgendaStore.loading
    )
      return <Spinner />;

    const handleAdd = async ({
      station_id,
      work_order_id,
      server_id,
    }: {
      station_id: number;
      work_order_id: number;
      server_id: number;
    }) => {
      await tripAgendaStore.addAgenda({
        trip_id: +id!,
        prev_trip_agenda_id: currentAgendaId,
        station_id: station_id,
        work_order_id: work_order_id,
        server_id: server_id,
      });
     if (id) await tripAgendaStore.fetchAgendaById(id);
    };

    return (
      <>
        <div className="map-container">
          <MapContainer
            center={[29.12932, 81.774391]}
            zoom={7}
            style={{ height: "80vh" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {showWorkOrders &&
              workOrderStore.items
                .filter((item) =>
                  workOrderStore.selectedServers.some(
                    (selectedServer) => selectedServer.value === item.server
                  )
                )
                .flatMap((serverWorkOrders) => {
                  return serverWorkOrders.workOrders
                    .filter((workOrder) =>
                      workOrderStore.showArchivedWorkOrders
                        ? true
                        : !workOrder.archived
                    )
                    .map((workOrder, index) => {
                      const stationServer = stationStore.items.find(
                        (serverStations) =>
                          serverStations.server_id ===
                          serverWorkOrders.server_id
                      );
                      const station =
                        workOrder.station &&
                        stationServer?.stations.find(
                          (station) => station.id === workOrder.station[0]?.id
                        );

                      if (!station || !station.latitude || !station.longitude)
                        return null;

                      //if workorder already added to agenda, don't show it
                      if (
                        sortedAgendas.find(
                          (agenda) =>
                            agenda.work_order_id === +workOrder.id &&
                            agenda.server_id === +serverWorkOrders.server_id
                        )
                      )
                        return null;

                      let mapColor = "black";

                      const statusServer = workOrderStatusStore.items.find(
                        (serverStatuses) =>
                          serverStatuses.server_id ===
                          serverWorkOrders.server_id
                      );
                      const status = statusServer?.data.find(
                        (status) => status.id === workOrder.status
                      );

                      const priorityServer = workOrderPriorityStore.items.find(
                        (serverPriorities) =>
                          serverPriorities.server_id ===
                          serverWorkOrders.server_id
                      );
                      const priority = priorityServer?.data.find(
                        (priority) => priority.id === workOrder.priority
                      );

                      const maintenanceTypeServer =
                        workOrderMaintenanceTypeStore.items.find(
                          (serverMaintenanceTypes) =>
                            serverMaintenanceTypes.server_id ===
                            serverWorkOrders.server_id
                        );
                      const maintenanceType = maintenanceTypeServer?.data.find(
                        (maintenanceType) =>
                          maintenanceType.id === workOrder.maintenance_type
                      );

                      const creation_date = new Date(
                        workOrder.creation_date
                      ).toLocaleDateString();

                      const completion_deadline = new Date(
                        workOrder.completion_deadline
                      ).toLocaleDateString();

                      const scheduled_date = new Date(
                        workOrder.scheduled_date
                      ).toLocaleDateString();

                      const ticket =
                        workOrder.ticket && workOrder.ticket[0]?.summary;

                      const userServer = userStore.items.find(
                        (serverUsers) =>
                          serverUsers.server_id === serverWorkOrders.server_id
                      );

                      const createdBy = userServer?.users.find(
                        (user: any) => +user.id === workOrder.created_by
                      );

                      const assignedTo = userServer?.users.find(
                        (user: any) => +user.id === workOrder.assigned_to
                      );

                      if (workOrderStore.selectedFilter === "status") {
                        mapColor = status?.color ? status?.color : "black";
                      }

                      if (workOrderStore.selectedFilter === "priority") {
                        mapColor = priority?.color ? priority?.color : "black";
                      }

                      if (workOrderStore.selectedFilter === "type") {
                        mapColor = maintenanceType?.color
                          ? maintenanceType?.color
                          : "black";
                      }

                      if (
                        workOrderStore.selectedFilter === "status" &&
                        workOrderStore.selectedFilterValue &&
                        status?.caption !== workOrderStore.selectedFilterValue
                      ) {
                        return null;
                      }

                      if (
                        workOrderStore.selectedFilter === "priority" &&
                        workOrderStore.selectedFilterValue &&
                        priority?.caption !== workOrderStore.selectedFilterValue
                      ) {
                        return null;
                      }

                      if (
                        workOrderStore.selectedFilter === "type" &&
                        workOrderStore.selectedFilterValue &&
                        maintenanceType?.caption !==
                          workOrderStore.selectedFilterValue
                      ) {
                        return null;
                      }

                      if (
                        workOrderStore.selectedFilter === "assigned" &&
                        workOrderStore.selectedFilterValue &&
                        assignedTo?.email !== workOrderStore.selectedFilterValue
                      ) {
                        return null;
                      }

                      return (
                        <CircleMarker
                          center={[station.latitude, station.longitude]}
                          pathOptions={{ color: mapColor }}
                          key={`${workOrder.id}-${index}`}
                          radius={5}
                        >
                          <Popup>
                            <div>
                              <h3>{station.name}</h3>
                              <h3>{workOrder.title}</h3>
                              <p>Status : {status?.caption}</p>
                              <p>Priority : {priority?.caption}</p>
                              <p>
                                Maintenance Type : {maintenanceType?.caption}
                              </p>
                              <p>Assigned to : {assignedTo?.email}</p>

                              <button
                                className="button"
                                onClick={() => {
                                  handleAdd({
                                    work_order_id: +workOrder.id,
                                    station_id: station.id,
                                    server_id: +serverWorkOrders.server_id,
                                  });
                                }}
                              >
                                Add agenda
                              </button>
                            </div>
                          </Popup>
                        </CircleMarker>
                      );
                    });
                })}
            {sortedAgendas
              .filter((sortedAgenda) => sortedAgenda?.latitude)
              .map((agenda, index) => {
                return (
                  <NumberedMarker
                    key={index}
                    position={[agenda.latitude, agenda.longitude]}
                    number={index + 1}
                    className={
                      currentAgendaId === +agenda.id
                        ? "number-marker selected"
                        : "number-marker"
                    }
                  >
                    <Popup>
                      <div>
                        <h3>{agenda.station}</h3>
                        <p>{agenda.work_order}</p>
                      </div>
                    </Popup>
                  </NumberedMarker>
                );
              })}
          </MapContainer>
        </div>
      </>
    );
  }
);
