import { makeAutoObservable, runInAction } from "mobx";
import { callApi } from "../actions/apiMethods";
import { toast } from "react-toastify";

export class tripStore {
	items = [];
	loading = false;

	constructor() {
		makeAutoObservable(this);
	}

	addTrip = async (formEntries) => {
		const response = await callApi("POST", "api/trip", formEntries);
		if (!response) return;
		runInAction(() => {
			this.items.push(response[0]);
		});
		toast.success("Trip added");
		return "Trip added";
	};

	fetchItems = async () => {
		const response = await callApi("GET", "api/trip");
		if (!response) return;
		runInAction(() => {
			this.items = response;
		});
	};

	deleteTrip = async (tripid) => {
		const response = await callApi("DELETE", `api/trip/${tripid}`);
		if (!response) return;
		runInAction(() => {
			this.items = this.items.filter((trip) => +trip.id !== +tripid);
		});
		toast.success("Trip deleted");
	};

	editTrip = async (tripid, updatedTripData) => {
		const response = await callApi("PUT", `api/trip/${tripid}`, updatedTripData);
		if (!response) return;
		runInAction(() => {
			this.items = this.items.map((trip) => (trip.id === tripid ? { ...trip, ...updatedTripData } : trip));
		});
		toast.success("Trip updated");
		return "Trip updated";
	};
}
