import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { DataTable } from "../../Common/DataTable/DataTable";
import { Spinner } from "../../Common/Spinner";
import { useNavigate } from "react-router-dom";

export const WorkOrderTabular = observer(() => {
  const rootStore = useContext(RootContext);
  const workOrderStore = rootStore.workOrderStore;
  const workOrderStatusStore = rootStore.workOrderStatusStore;
  const workOrderPriorityStore = rootStore.workOrderPriorityStore;
  const workOrderMaintenanceTypeStore = rootStore.workOrderMaintenanceTypeStore;
  const userStore = rootStore.userStore;
  const stationStore = rootStore.stationStore;

  const navigate = useNavigate();

  if (
    workOrderStore.loading ||
    workOrderStatusStore.loading ||
    workOrderPriorityStore.loading ||
    workOrderMaintenanceTypeStore.loading ||
    userStore.loading ||
    stationStore.loading
  )
    return <Spinner />;

  return (
    <>
      <div>
        <DataTable
          data={workOrderStore.filteredItems.map((workOrder: any) => {
            return {
              ...workOrder,
              server: workOrder.server.split("//")[1] || workOrder.server,
              station: workOrder.station?.map((s: any) => s?.name).join(", "),
              title: (
                <p
                  style={{cursor: "pointer", textDecoration: "underline",color: "#3c8dbc" }}
                  onClick={() => {
                    workOrderStore.setSelectedServer(workOrder.server);
                    navigate(`/workorder_detail/${workOrder.work_order_id}`);
                  }}
                >
                  {workOrder.title}
                </p>
              ),
            }
          })}
          headers={[
            { key: "server", label: "Server" },
            { key: "title", label: "Title" },
            { key: "archived", label: "Archived" },
            { key: "station", label: "Station" },
            { key: "status", label: "Status" },
            { key: "priority", label: "Priority" },
            { key: "maintenance_type", label: "Maintenance Type" },
            { key: "creation_date", label: "Creation Date" },
            { key: "completion_deadline", label: "Completion Deadline" },
            { key: "scheduled_date", label: "Scheduled Date" },
            { key: "ticket", label: "Ticket" },
            { key: "created_by", label: "Created By" },
            { key: "assigned_to", label: "Assigned To" },
          ]}
        />
      </div>
    </>
  );
});
