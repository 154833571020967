import { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { getFromLocalStorage } from "../../localStorage";
import { Modal } from "../../Common/Modal/Modal";
import Select from "react-select";
import { toast } from "react-toastify";

export const AddLog = observer(({ closeModal }: { closeModal: () => void }) => {
  const rootStore = useContext(RootContext);
  const userStore = rootStore.userStore;
  const inventoryItemStore = rootStore.inventoryItemStore;
  const inventoryProductStore = rootStore.inventoryProductStore;
  const workOrderStore = rootStore.workOrderStore;
  const serverStore = rootStore.serverStore;

  const { id } = useParams();

  const [message, setMessage] = useState("");
  const [assigned_to, setAssignedTo] = useState<{
    label: "string";
    value: "string";
  }>();
  const [item_id, setItemId] = useState<{
    label: "string";
    value: "string";
  }>();
  const [actual_man_hours, setActualManHours] = useState("");

  const userOptions = userStore.items.find(
    (item) => item.server === workOrderStore.selectedServer
  ).users;

  const inventoryItemsOptions = inventoryItemStore.items
    .find((item) => item.server === workOrderStore.selectedServer)
    .items.map((item: any) => {
      let inventoryProduct = inventoryProductStore.items
        .find((item) => item.server === workOrderStore.selectedServer)!
        .items.find((product: any) => +product.id === +item.product_id);
      return {
        label: `${inventoryProduct.pcategory_name} - ${item.serial_no}`,
        value: item.id,
      };
    });

  const userServer = serverStore.user_servers.find(
    (server) => server.domain_name === workOrderStore.selectedServer
  );
  const user = userOptions.find(
    (user: any) => user.username === userServer!.server_username
  );

  const addLog = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!user) {
      toast.error(`Not logged in to server ${workOrderStore.selectedServer}`);
      return;
    }
    const token = getFromLocalStorage(workOrderStore.selectedServer);
    fetch(`${workOrderStore.selectedServer}/mms/work_order/${id}/log`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        actual_man_hours: actual_man_hours || null,
        assigned_to: assigned_to ? assigned_to.value : null,
        email: { subject: `Work order ${id}`, link: "" },
        followers: [],
        item_id: item_id ? item_id.value : null,
        log_date: new Date().toISOString(),
        message: { text: message },
        sender: { name: user.name, email: user.email },
        user_id: +user.id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        closeModal();
      })
      .catch((e) => {
        toast.error("Error adding log");
        console.log(e);
      });
  };
  return (
    <>
      <Modal title="Add Log" closeModal={() => closeModal()}>
        <form className="Form" autoComplete="off">
          <label>Log</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows={4}
            placeholder="note..."
          />
          <label>Assigned to</label>
          <Select
            className="form-select"
            placeholder="Assigned to"
            value={assigned_to}
            onChange={(e: any) => setAssignedTo(e)}
            options={userOptions.map((user: any) => {
              return {
                label: user.name,
                value: user.id,
              };
            })}
          />
          <label>Item</label>
          <Select
            className="form-select"
            placeholder="Item"
            value={item_id}
            onChange={(e: any) => setItemId(e)}
            options={inventoryItemsOptions}
          />
          <label>Actual man hours</label>
          <input
            value={actual_man_hours}
            onChange={(e) => setActualManHours(e.target.value)}
          />
          <button className="button" onClick={(e) => addLog(e)}>
            Save
          </button>
        </form>
      </Modal>
    </>
  );
});
