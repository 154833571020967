export const LoadingButton = ({
  loading,
  onClick,
  children,
}: {
  loading: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  children: React.ReactNode;
}) => (
  <button onClick={onClick} disabled={loading} className="button">
    {loading ? "Loading..." : children}
  </button>
);
