import { makeAutoObservable, runInAction } from "mobx";
import { getAll } from "../actions/apiMethods";

export class inventoryProductStore {
  items = [];
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchItems = async () => {
    this.loading = true;
    const response = await getAll("inventory/product");
    if (!response) return;
    runInAction(() => {
      this.items = response.message;
      this.loading = false;
    });
  };
}
