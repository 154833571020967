import L from "leaflet";
import { Marker } from "react-leaflet";

export const NumberedMarker = ({
  position,
  number,
  children,
  className,
}: {
  position: [number, number];
  number: number;
  children?: React.ReactNode;
  className: string;
}) => {
  // Create custom marker icon
  const markerIcon = L.divIcon({
    className: "custom-div-icon",
    html: `<div class="${className}">
    ${number}
    </div>`,
  });

  return (
    <Marker position={position} icon={markerIcon}>
      {children}
    </Marker>
  );
};
