import { observer } from "mobx-react-lite";

export const DateInput = observer(
  ({
    label,
    value = "",
    readOnly,
    onChange,
    type,
    name,
  }: {
    label: string;
    value?: string;
    readOnly?: boolean;
    onChange: (e: any) => void;
    type?: string;
    name?: string;
  }) => (
    <div className="formInput">
      <label>{label}</label>
      <input
        name={name || ""}
        type={type ? type : "date"}
        value={value || ""}
        readOnly={readOnly}
        onChange={onChange}
      />
    </div>
  )
);
