import { useEffect, useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { DataTable } from "../../Common/DataTable/DataTable";
import { Spinner } from "../../Common/Spinner";

export const TicketTabular = observer(() => {
  const rootStore = useContext(RootContext);
  const userStore = rootStore.userStore;
  const stationStore = rootStore.stationStore;
  const ticketStore = rootStore.ticketStore;
  const ticketActionStore = rootStore.ticketActionStore;
  const ticketReasonStore = rootStore.ticketReasonStore;
  const ticketStatusStore = rootStore.ticketStatusStore;

  // console.log(ticketActionStore.loading, ticketReasonStore.loading, ticketStatusStore.loading, userStore.loading, stationStore.loading, ticketStore.loading);
  if (ticketActionStore.loading || ticketReasonStore.loading || ticketStatusStore.loading || userStore.loading || stationStore.loading || ticketStore.loading) return <Spinner />;

  return (
    <>
      <DataTable
        data={ticketStore.filteredItems}
        headers={[
          { key: "server", label: "Server" },
          { key: "summary", label: "Summary" },
          { key: "generated_date", label: "Generated Date" },
          { key: "description", label: "Description" },
          { key: "archived", label: "Archived" },
          { key: "station", label: "Station" },
          { key: "status", label: "Status" },
          { key: "reason", label: "Reason" },
          { key: "action", label: "Action" },
          { key: "assigned_to", label: "Assigned To" },
        ]}
      />
    </>
  );
});
