import { commonStore } from "./common_store";
import { serverStore } from "./server_store";
import { tripStationStore } from "./tripStation_store";
import { tripStore } from "./trip_store";
import { workOrderStore } from "./work_order_store";
import { workOrderStatusStore } from "./work_order_status_store";
import { workOrderPriorityStore } from "./work_order_priority_store";
import { workOrderMaintenanceTypeStore } from "./work_order_maintenance_type_store";
import { userStore } from "./user_store";
import { mmsUserStore } from "./mms_user_store";
import { stationStore } from "./station_store";
import { ticketStore } from "./ticket_store";
import { ticketActionStore } from "./ticket_action_store";
import { ticketReasonStore } from "./ticket_reason_store";
import { ticketStatusStore } from "./ticket_status_store";
import { tripAgendaStore } from "./trip_agenda_store";
import { inventoryItemStore } from "./inventory_item_store";
import { inventoryProductStore } from "./inventory_product_store";

export class RootStore {
	constructor() {
		this.commonStore = new commonStore();
		this.serverStore = new serverStore();
		this.tripStationStore = new tripStationStore();
		this.tripStore = new tripStore();
		this.workOrderStatusStore = new workOrderStatusStore();
		this.workOrderPriorityStore = new workOrderPriorityStore();
		this.workOrderMaintenanceTypeStore = new workOrderMaintenanceTypeStore();
		this.userStore = new userStore();
		this.mmsUserStore = new mmsUserStore();
		this.stationStore = new stationStore();
		this.ticketActionStore = new ticketActionStore();
		this.ticketReasonStore = new ticketReasonStore();
		this.ticketStatusStore = new ticketStatusStore();
		this.ticketStore = new ticketStore(this);
		this.workOrderStore = new workOrderStore(this);
		this.tripAgendaStore = new tripAgendaStore();
		this.inventoryItemStore = new inventoryItemStore();
		this.inventoryProductStore = new inventoryProductStore();
	}
}
