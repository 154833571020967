import { jwtDecode } from "jwt-decode";
import { token } from "../localStorage";
import { redirect } from "react-router-dom";
import { decodedToken } from "../types";

export const isLoggedIn = () => {
  const localToken = token.get();
  if (!localToken) return false;
  const decoded = jwtDecode(localToken) as decodedToken;
  if (decoded.exp > Date.now() / 1000) {
    return true;
  } else {
    token.remove();
    return false;
  }
};

export const checkRedirectToLogin = () => {
  if (!isLoggedIn()) return redirect("/login");
  return true;
};
