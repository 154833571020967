import { useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { Spinner } from "../../Common/Spinner";
import { MapContainer, TileLayer, Popup, CircleMarker } from "react-leaflet";
import { useNavigate } from "react-router-dom";

export const WorkOrderMap = observer(() => {
    const rootStore = useContext(RootContext);
    const workOrderStore = rootStore.workOrderStore;
    const workOrderStatusStore = rootStore.workOrderStatusStore;
    const workOrderPriorityStore = rootStore.workOrderPriorityStore;
    const workOrderMaintenanceTypeStore = rootStore.workOrderMaintenanceTypeStore;
    const userStore = rootStore.userStore;
    const stationStore = rootStore.stationStore;

    const navigate = useNavigate();

    if (
      workOrderStore.loading ||
      workOrderStatusStore.loading ||
      workOrderPriorityStore.loading ||
      workOrderMaintenanceTypeStore.loading ||
      userStore.loading ||
      stationStore.loading
    )
      return <Spinner />;

    return (
      <>
        <div className="map-container">
          <MapContainer
            center={[29.12932, 81.774391]}
            zoom={7}
            style={{ height: "80vh" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {workOrderStore.filteredItems.filter((workOrder: any) => workOrder.station).map((workOrder: any) => {
              const stationLocation = workOrder.station?.find((station: any) => station?.latitude && station?.longitude);
              if (!stationLocation) return null;
              return (
                <CircleMarker
                  center={[stationLocation.latitude!, stationLocation.longitude!]}
                  pathOptions={{ color: workOrder.mapColor }}
                  radius={5}
                >
                  <Popup>
                    <div>
                      <h3>{stationLocation.name}</h3>
                      <h3
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                          color: "#3c8dbc",
                        }}
                        onClick={() => {
                          workOrderStore.setSelectedServer(workOrder.server);
                          navigate(`/workorder_detail/${workOrder.work_order_id}`);
                        }}
                      >
                        {workOrder.title}
                      </h3>
                      <p>Status : {workOrder?.status}</p>
                      <p>Priority : {workOrder?.priority}</p>
                      <p>Maintenance Type : {workOrder?.maintenance_type}</p>
                      <p>Assigned to : {workOrder?.assigned_to}</p>
                    </div>
                  </Popup>
                </CircleMarker>
              );
            })}
          </MapContainer>
        </div>
      </>
    );
  }
);
