import { useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { Spinner } from "../../Common/Spinner";
import { MapContainer, TileLayer, Popup, CircleMarker } from "react-leaflet";
import "leaflet/dist/leaflet.css";

export const TicketMap = observer(() => {
  const rootStore = useContext(RootContext);
  const userStore = rootStore.userStore;
  const stationStore = rootStore.stationStore;
  const ticketStore = rootStore.ticketStore;
  const ticketActionStore = rootStore.ticketActionStore;
  const ticketReasonStore = rootStore.ticketReasonStore;
  const ticketStatusStore = rootStore.ticketStatusStore;

  if (
    ticketActionStore.loading ||
    ticketReasonStore.loading ||
    ticketStatusStore.loading ||
    userStore.loading ||
    stationStore.loading ||
    ticketStore.loading
  )
    return <Spinner />;

  return (
    <>
      <div className="map-container">
        <MapContainer
          center={[29.12932, 81.774391]}
          zoom={7}
          style={{ height: "80vh" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {ticketStore.filteredItems.filter(((ticket: any) => ticket.latitude && ticket.longitude)).map((ticket: any) => {
            return (
              <CircleMarker
                center={[ticket.latitude, ticket.longitude]}
                pathOptions={{ color: ticket.mapColor }}
                radius={5}
              >
                <Popup>
                  <div>
                    <h3>{ticket.name}</h3>
                    <h3>{ticket.summary}</h3>
                    <p>{ticket.description}</p>
                  </div>
                </Popup>
              </CircleMarker>
            );
          })}
        </MapContainer>
      </div>
    </>
  );
});
