import { makeAutoObservable, runInAction } from "mobx";
import { getAll } from "../actions/apiMethods";
import { allTickets, server } from "../types";

export class ticketStore {
  items: Array<allTickets> = [];
  loading = false;
  globalStore: any;

  selectedServers: Array<server> = [];
  showArchivedTickets = false;

  selectedFilter = "status";
  selectedFilterValue = "";

  viewByOptions = [
    { id: 1, label: "Status", value: "status" },
    { id: 2, label: "Reason", value: "reason" },
    { id: 3, label: "Action", value: "action" },
    { id: 4, label: "Assigned To", value: "assigned" },
  ];

  constructor(globalStore: any) {
    makeAutoObservable(this);
    this.globalStore = globalStore;
  }

  fetchItems = async () => {
    this.loading = true;
    const response = await getAll("tickets");
    if (!response) return;
    runInAction(() => {
      this.items = response.message;
      this.loading = false;
    });
  };

  setSelectedServers = (servers: Array<server>) => {
    this.selectedServers = servers;
  };

  setShowArchivedTickets = () => {
    this.showArchivedTickets = !this.showArchivedTickets;
  };

  setSelectedFilter = (filter: string) => {
    this.selectedFilter = filter;
  };

  setSelectedFilterValue = (value: string) => {
    this.selectedFilterValue = value;
  };

  get selectedServerValues() {
    return this.selectedServers.map((server) => server.value);
  }
  get filteredItems() {
    const { ticketStatusStore, ticketReasonStore, ticketActionStore, stationStore, userStore } = this.globalStore;

    if (ticketActionStore.loading || ticketReasonStore.loading || ticketStatusStore.loading || userStore.loading || stationStore.loading || this.loading) return [];


    const filteredItems = this.items
      .reduce((allItems: any, serverTickets: any) => {
        if (this.selectedServerValues.indexOf(serverTickets.server) === -1) return allItems;
        const serverItems = serverTickets.tickets.reduce((acc: any, ticket: any) => {
          if (this.showArchivedTickets ? true : !ticket.archived) return acc;
          const server_id = serverTickets.server_id;

          const station = stationStore.keyItems[`key_${server_id}`][`key_${ticket.station_id}`];
          const status = ticketStatusStore.keyItems[`key_${server_id}`][`key_${ticket.status_id}`];
          const reason = ticketReasonStore.keyItems[`key_${server_id}`][`key_${ticket.reason_id}`];
          const action = ticketActionStore.keyItems[`key_${server_id}`][`key_${ticket.action_id}`];
          const assignedTo = userStore.keyItems[`key_${server_id}`][`key_${ticket.assigned_to}`];


          if (this.selectedFilter === "status" && this.selectedFilterValue && status?.caption !== this.selectedFilterValue) return acc;
          if (this.selectedFilter === "reason" && this.selectedFilterValue && reason?.caption !== this.selectedFilterValue) return acc;
          if (this.selectedFilter === "action" && this.selectedFilterValue && action?.caption !== this.selectedFilterValue) return acc;
          if (this.selectedFilter === "assigned" && this.selectedFilterValue && assignedTo?.email !== this.selectedFilterValue) return acc;

          let mapColor = "black";
          if(this.selectedFilter === "status" && status?.color) mapColor = status.color;
          if(this.selectedFilter === "reason" && reason?.color) mapColor = reason.color;
          if(this.selectedFilter === "action" && action?.color) mapColor = action.color;
          if(this.selectedFilter === "assigned" && assignedTo?.color) mapColor = assignedTo.color;

          acc.push({
            server: serverTickets.server,
            summary: ticket.summary,
            generated_date: new Date(ticket.generated_date).toLocaleDateString(),
            description: ticket.description,
            archived: ticket.archived ? "Yes" : "No",
            station: station?.name,
            status: status?.caption,
            action: action?.caption,
            reason: reason?.caption,
            assigned_to: assignedTo?.email,
            latitude: station?.latitude,
            longitude: station?.longitude,
            mapColor,
          });
          return acc;
        }, []);
        allItems = [...allItems, ...serverItems];
        return allItems;
      }, []);
    return filteredItems;
  }
}
