import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";

const MultiValueContainer = ({ children, ...props }) => {
  const totalCount = props.selectProps.options.length;
  const selectedCount = props.selectProps.value.length < totalCount ? props.selectProps.value.length : totalCount - 1;
  const value = props.selectProps.value[0];
  return (
    <components.ValueContainer {...props}>
      <div>
        {React.cloneElement(children[1])}
        <div style={{ borderTop: '1px solid' }}>
          {`Selected ${value ? (value.label === 'Select all' ? totalCount - 1 : selectedCount) : 0} of ${totalCount - 1}`}
        </div>
      </div>
      <br />
    </components.ValueContainer>
  )
};

export const MultiSelect = ({ options, value, onChange, filterOption }) => {
  let [searchField, setSearchField] = useState(null);

  const handleChange = (opt, { option }) => {
    let newOpts = opt;
    let string = searchField;

    if (option && option.value === "all") {
      let toSetOptions = options.filter((option) => filterOption(option, string));
      newOpts = newOpts.concat(toSetOptions).filter(newOpt => newOpt.value !== "all");
    }
    // setSearchField(null);
    onChange(newOpts);
  };

  const onInputChange = (string, { action }) => {
    if (action === "input-change") {
      setSearchField(string);
    }
    return string;
  };

  const handleFilter = (option, string) => {
    if (value === "all" || option.value === "all") {
      return true;
    } else if (string) {
      return filterOption(option.data, string);
    } else {
      return true;
    }
  };

  return (
    <Select
      isMulti
      filterOption={handleFilter}
      inputValue={searchField}
      onInputChange={onInputChange}
      onChange={handleChange}
      options={[{ label: "Select all", value: "all" }, ...options]}
      value={value}
      components={{ ValueContainer: MultiValueContainer }}
      hideSelectedOptions={false}
    />
  );
}