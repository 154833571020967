import { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { getFromLocalStorage } from "../../localStorage";
import { Modal } from "../../Common/Modal/Modal";
import Select from "react-select";
import { toast } from "react-toastify";

export const AddTask = observer(
  ({ closeModal }: { closeModal: () => void }) => {
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const inventoryItemStore = rootStore.inventoryItemStore;
    const inventoryProductStore = rootStore.inventoryProductStore;
    const workOrderStore = rootStore.workOrderStore;
    const serverStore = rootStore.serverStore;

    const { id } = useParams();

    const [description, setDescription] = useState("");
    const [item, setItem] = useState<{
      label: "string";
      value: "string";
    }>();
    const [assigned_to, setAssignedTo] = useState<{
      label: "string";
      value: "string";
    }>();
    const [estimated_man_hours, setEstimatedManHours] = useState("");
    const [hours_spent, setHoursSpent] = useState("");
    const [task_result, setTaskResult] = useState("");
    const [task_status, setTaskStatus] = useState("");

    const userOptions = userStore.items.find(
      (item) => item.server === workOrderStore.selectedServer
    ).users;

    const inventoryItemsOptions = inventoryItemStore.items
      .find((item) => item.server === workOrderStore.selectedServer)
      .items.map((item: any) => {
        let inventoryProduct = inventoryProductStore.items
          .find((item) => item.server === workOrderStore.selectedServer)!
          .items.find((product: any) => +product.id === +item.product_id);
        return {
          label: `${inventoryProduct.pcategory_name} - ${item.serial_no}`,
          value: item.id,
        };
      });

    const userServer = serverStore.user_servers.find(
      (server) => server.domain_name === workOrderStore.selectedServer
    );
    const user = userOptions.find(
      (user: any) => user.username === userServer!.server_username
    );

    const addTask = async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (!user) {
        toast.error(`Not logged in to server ${workOrderStore.selectedServer}`);
        return;
      }
      const token = getFromLocalStorage(workOrderStore.selectedServer);
      fetch(`${workOrderStore.selectedServer}/mms/work_order/${id}/tasks`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          assigned_to: assigned_to ? assigned_to.value : null,
          description,
          estimated_man_hours: estimated_man_hours || null,
          hours_spent: hours_spent || null,
          item_id: item ? item.value : null,
          task_result,
          task_status,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          closeModal();
        })
        .catch((e) => {
          toast.error("Error adding task");
          console.log(e);
        });
    };
    return (
      <>
        <Modal title="Add Task" closeModal={() => closeModal()}>
          <form className="Form" autoComplete="off">
            <label>Description</label>
            <input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <label>Item</label>
            <Select
              className="form-select"
              placeholder="Item"
              value={item}
              onChange={(e: any) => setItem(e)}
              options={inventoryItemsOptions}
            />

            <label>Assigned to</label>
            <Select
              className="form-select"
              placeholder="Assigned to"
              value={assigned_to}
              onChange={(e: any) => setAssignedTo(e)}
              options={userOptions.map((user: any) => {
                return {
                  label: user.name,
                  value: user.id,
                };
              })}
            />

            <label>Estimated man hours</label>
            <input
              value={estimated_man_hours}
              onChange={(e) => setEstimatedManHours(e.target.value)}
            />

            <label>Hours spent</label>
            <input
              value={hours_spent}
              onChange={(e) => setHoursSpent(e.target.value)}
            />

            <label>Task result</label>
            <input
              value={task_result}
              onChange={(e) => setTaskResult(e.target.value)}
            />

            <label>Task status</label>
            <input
              value={task_status}
              onChange={(e) => setTaskStatus(e.target.value)}
            />

            <button className="button" onClick={(e) => addTask(e)}>
              Save
            </button>
          </form>
        </Modal>
      </>
    );
  }
);
