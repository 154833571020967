import { makeAutoObservable, runInAction, toJS } from "mobx";
import { getAll } from "../actions/apiMethods";
import { allWorkOrders, server } from "../types";

export class workOrderStore {
  globalStore: any;
  items: Array<allWorkOrders> = [];
  loading = false;
  selectedServers: Array<server> = [];
  showArchivedWorkOrders = false;
  selectedStations: Array<any> = [];

  selectedFilter = "status";
  selectedFilterValue = "";

  selectedServer = "";

  viewByOptions = [
    { id: 1, label: "Status", value: "status" },
    { id: 2, label: "Priority", value: "priority" },
    { id: 3, label: "Type", value: "type" },
    { id: 4, label: "Assigned To", value: "assigned" },
    { id: 5, label: "Server", value: "server" },
  ];

  constructor(globalStore: any) {
    makeAutoObservable(this);
    this.globalStore = globalStore;
  }

  fetchItems = async () => {
    this.loading = true;
    const response = await getAll("workorder");
    if (!response) return;
    runInAction(() => {
      this.items = response.message;
      this.loading = false;
    });
  };

  setSelectedServers = (servers: Array<server>) => {
    this.selectedServers = servers;
  };

  setSelectedStations = (stations: Array<any>) => {
    this.selectedStations = stations;
  };

  setShowArchivedWorkOrders = () => {
    this.showArchivedWorkOrders = !this.showArchivedWorkOrders;
  };

  setSelectedFilter = (filter: string) => {
    this.selectedFilter = filter;
  };

  setSelectedFilterValue = (value: string) => {
    this.selectedFilterValue = value;
  };

  setSelectedServer = (server: string) => {
    this.selectedServer = server;
  };

  get selectedServerValues() {
    return this.selectedServers.map((server) => server.value);
  }

  get filteredItems() {
    const { workOrderStatusStore, workOrderPriorityStore, workOrderMaintenanceTypeStore, userStore, stationStore } = this.globalStore;

    if (workOrderStatusStore.loading || workOrderPriorityStore.loading || workOrderMaintenanceTypeStore.loading || userStore.loading || stationStore.loading || this.loading) return [];

    const filteredItems = this.items
      .reduce((allItems: any, serverWorkOrders) => {
        if (this.selectedServerValues.indexOf(serverWorkOrders.server) === -1) return allItems;
        const serverItems = serverWorkOrders.workOrders.reduce((acc: any, workorder) => {
          if (this.showArchivedWorkOrders ? true : !workorder.archived) return acc;
          const server_id = serverWorkOrders.server_id;

          const status = workOrderStatusStore.keyItems[`key_${server_id}`][`key_${workorder.status}`];
          const priority = workOrderPriorityStore.keyItems[`key_${server_id}`][`key_${workorder.priority}`];
          const maintenance_type = workOrderMaintenanceTypeStore.keyItems[`key_${server_id}`][`key_${workorder.maintenance_type}`];
          const assignedTo = userStore.keyItems[`key_${server_id}`][`key_${workorder.assigned_to}`];
          const created_by = userStore.keyItems[`key_${server_id}`][`key_${workorder.created_by}`];

          const stations = workorder.station?.map((s) => {
            const station = stationStore.keyItems[`key_${server_id}`][`key_${s.id}`];
            return station ? toJS(station) : null;
          });


          if (this.selectedFilter === "status" && this.selectedFilterValue && status?.caption !== this.selectedFilterValue) return acc;
          if (this.selectedFilter === "priority" && this.selectedFilterValue && priority?.caption !== this.selectedFilterValue) return acc;
          if (this.selectedFilter === "type" && this.selectedFilterValue && maintenance_type?.caption !== this.selectedFilterValue) return acc;
          if (this.selectedFilter === "assigned" && this.selectedFilterValue && assignedTo?.email !== this.selectedFilterValue) return acc;
          if (this.selectedFilter === "server" && this.selectedFilterValue && serverWorkOrders.server !== this.selectedFilterValue) return acc;
          

          let mapColor = "black";
          if (this.selectedFilter === "status" && status?.color) mapColor = status.color;
          if (this.selectedFilter === "priority" && priority?.color) mapColor = priority.color;
          if (this.selectedFilter === "type" && maintenance_type?.color) mapColor = maintenance_type.color;
          if (this.selectedFilter === "assigned" && assignedTo?.color) mapColor = assignedTo.color;
          if (this.selectedFilter === "server")  mapColor = this.selectedServers.find(selectedServer => selectedServer.value === serverWorkOrders.server )?.color || "black";

          if(this.selectedStations.length > 0 && !workorder.station?.some((s) => this.selectedStations.some((selectedStation) => selectedStation.value === s.id))) return acc;

          acc.push({
            work_order_id: workorder.id,
            server: serverWorkOrders.server,
            title: workorder.title,
            archived: workorder.archived ? "Yes" : "No",
            station: stations,
            status: status?.caption,
            priority: priority?.caption,
            maintenance_type: maintenance_type?.caption,
            creation_date: new Date(workorder.creation_date).toLocaleDateString(),
            completion_deadline: new Date(workorder.completion_deadline).toLocaleDateString(),
            scheduled_date: new Date(workorder.scheduled_date).toLocaleDateString(),
            ticket: workorder.ticket && workorder.ticket[0]?.summary,
            created_by: created_by?.name,
            assigned_to: assignedTo?.email,
            mapColor,
          });
          return acc;
        }, []);
        allItems = [...allItems, ...serverItems];
        return allItems;
      }, []);

    return filteredItems;
  }
}
