
import { makeAutoObservable } from "mobx";


export class commonStore {
  otherTokens = [];

  constructor() {
    makeAutoObservable(this);
  }

  setOtherTokens = (otherTokens) => {
    this.otherTokens = otherTokens
  }
}