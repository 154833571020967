import "./App.css";
import NavBar from "./Components/NavBar/NavBar";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Outlet />
      <ToastContainer
        position="bottom-right"
        hideProgressBar={true}
        autoClose={2000}
        theme="dark"
      />
    </div>
  );
}

export default App;
