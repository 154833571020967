import { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { BooleanInput } from "../../Common/Inputs/BooleanInput";
import { extractUniqueItems } from "../../helpers/extractUniqueItems";
import { FaCircle } from "react-icons/fa";
import { toJS } from "mobx";
import { MultiSelect } from "../../Common/MultiSelect";

export const WorkOrderOptions = observer(() => {
  const rootStore = useContext(RootContext);
  const workOrderStore = rootStore.workOrderStore;
  const workOrderStatusStore = rootStore.workOrderStatusStore;
  const workOrderPriorityStore = rootStore.workOrderPriorityStore;
  const workOrderMaintenanceTypeStore = rootStore.workOrderMaintenanceTypeStore;
  const userStore = rootStore.userStore;
  const stationStore = rootStore.stationStore;
  const serverStore = rootStore.serverStore;

  const [uniqueStatus, setUniqueStatus] = useState<any[]>([]);
  const [uniquePriority, setUniquePriority] = useState<any[]>([]);
  const [uniqueMaintenanceType, setUniqueMaintenanceType] = useState<any[]>([]);
  const [uniqueUsers, setUniqueUsers] = useState<any[]>([]);

  useEffect(() => {
    if (!serverStore.user_servers.length) serverStore.fetchUserServers();
    if (!workOrderStore.items.length) workOrderStore.fetchItems();
    if (!workOrderStatusStore.items.length) workOrderStatusStore.fetchItems();
    if (!workOrderPriorityStore.items.length)
      workOrderPriorityStore.fetchItems();
    if (!workOrderMaintenanceTypeStore.items.length)
      workOrderMaintenanceTypeStore.fetchItems();
    if (!userStore.items.length) userStore.fetchItems();
    if (!stationStore.items.length) stationStore.fetchItems();
  }, []);

  useEffect(() => {
    if (!workOrderStore.selectedServers.length)
      workOrderStore.setSelectedServers(
        serverStore.user_servers
          .filter((server) =>
            Object.keys(localStorage).includes(server.domain_name)
          )
          .map((server) => {
            return {
              label: server.domain_name,
              value: server.domain_name,
              color: server.color,
            };
          })
      );
  }, [serverStore.user_servers]);

  useEffect(() => {
    setUniqueStatus(extractUniqueItems(workOrderStatusStore.items));
  }, [workOrderStatusStore.items]);

  useEffect(() => {
    setUniquePriority(extractUniqueItems(workOrderPriorityStore.items));
  }, [workOrderPriorityStore.items]);

  useEffect(() => {
    setUniqueMaintenanceType(
      extractUniqueItems(workOrderMaintenanceTypeStore.items)
    );
  }, [workOrderMaintenanceTypeStore.items]);

  useEffect(() => {
    setUniqueUsers(extractUniqueItems(userStore.items, "users", "email"));
  }, [userStore.items]);

  // console.log(toJS(stationStore.items));
  return (
    <div>
      <div>
        <Select
          isMulti
          className="form-select"
          placeholder="Servers"
          value={workOrderStore.selectedServers}
          onChange={(selectedoptions: any) => {
            workOrderStore.setSelectedServers(selectedoptions);
          }}
          options={serverStore.user_servers
            .filter((server) =>
              Object.keys(localStorage).includes(server.domain_name)
            )
            .map((server) => {
              return {
                label: server.domain_name,
                value: server.domain_name,
                color: server.color,
              };
            })}
        />
        <BooleanInput
          label="Show Archived"
          checked={workOrderStore.showArchivedWorkOrders}
          onToggle={workOrderStore.setShowArchivedWorkOrders}
        />
      </div>
      <div className="options">
        <div className="options-header">
          <ul>
            {workOrderStore.viewByOptions.map((option) => {
              return (
                <li
                  key={option.id}
                  onClick={() => {
                    workOrderStore.setSelectedFilter(option.value);
                    workOrderStore.setSelectedFilterValue("");
                  }}
                  className={
                    workOrderStore.selectedFilter === option.value
                      ? "active"
                      : ""
                  }
                >
                  {option.label}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="options-body">
          {workOrderStore.selectedFilter === "status" && (
            <table className="options-table">
              <tbody>
                {uniqueStatus.map((item) => (
                  <tr
                    key={item.caption}
                    onClick={() =>
                      workOrderStore.selectedFilterValue === item.caption
                        ? workOrderStore.setSelectedFilterValue("")
                        : workOrderStore.setSelectedFilterValue(item.caption)
                    }
                    className={
                      workOrderStore.selectedFilterValue === item.caption
                        ? "active"
                        : ""
                    }
                  >
                    <td>{item.caption}</td>
                    <td>
                      <FaCircle style={{ color: item.color }} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {workOrderStore.selectedFilter === "priority" && (
            <table className="options-table">
              <tbody>
                {uniquePriority.map((item) => (
                  <tr
                    onClick={() =>
                      workOrderStore.selectedFilterValue === item.caption
                        ? workOrderStore.setSelectedFilterValue("")
                        : workOrderStore.setSelectedFilterValue(item.caption)
                    }
                    className={
                      workOrderStore.selectedFilterValue === item.caption
                        ? "active"
                        : ""
                    }
                  >
                    <td>{item.caption}</td>
                    <td>
                      <FaCircle style={{ color: item.color }} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {workOrderStore.selectedFilter === "type" && (
            <table className="options-table">
              <tbody>
                {uniqueMaintenanceType.map((item) => (
                  <tr
                    onClick={() =>
                      workOrderStore.selectedFilterValue === item.caption
                        ? workOrderStore.setSelectedFilterValue("")
                        : workOrderStore.setSelectedFilterValue(item.caption)
                    }
                    className={
                      workOrderStore.selectedFilterValue === item.caption
                        ? "active"
                        : ""
                    }
                  >
                    <td>{item.caption}</td>
                    <td>
                      <FaCircle style={{ color: item.color }} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {workOrderStore.selectedFilter === "assigned" && (
            <table className="options-table">
              <tbody>
                {uniqueUsers
                  .filter((item) => item.email)
                  .map((item) => (
                    <tr
                      onClick={() =>
                        workOrderStore.selectedFilterValue === item.email
                          ? workOrderStore.setSelectedFilterValue("")
                          : workOrderStore.setSelectedFilterValue(item.email)
                      }
                      className={
                        workOrderStore.selectedFilterValue === item.email
                          ? "active"
                          : ""
                      }
                    >
                      <td>{item.email}</td>
                      <td>
                        <FaCircle style={{ color: item.color }} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}

          {workOrderStore.selectedFilter === "server" && (
            <>
              <table className="options-table">
                <tbody>
                  {workOrderStore.selectedServers.map((item) => (
                    <tr
                      onClick={() => {
                        workOrderStore.selectedFilterValue === item.value
                          ? workOrderStore.setSelectedFilterValue("")
                          : workOrderStore.setSelectedFilterValue(item.value);
                        workOrderStore.setSelectedStations([]);
                      }}
                      className={
                        workOrderStore.selectedFilterValue === item.value
                          ? "active"
                          : ""
                      }
                    >
                      <td>{item.label}</td>
                      <td>
                        <FaCircle
                          style={{
                            color: item.color,
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>

        {workOrderStore.selectedFilter === "server" &&
          workOrderStore.selectedFilterValue && (
            <div style={{ padding: "10px" }}>
              <label>STATIONS/TAGS</label>
              <MultiSelect
                options={stationStore.items
                  .find(
                    (item) => item.server === workOrderStore.selectedFilterValue
                  )
                  ?.stations.map((station) => {
                    return {
                      label: station.name,
                      value: station.id,
                      tags: station.tags,
                    };
                  })}
                value={workOrderStore.selectedStations}
                onChange={(selectedoptions: any) => {
                  workOrderStore.setSelectedStations(selectedoptions);
                }}
                //option label, value, tags
                filterOption={(option: any, rawInput: any) => {
                  if (!rawInput) return true;
                  const input = rawInput.toLowerCase();
                  return (
                    option.label.toLowerCase().includes(input) ||
                    option.tags?.some((tag: any) =>
                      tag.name.toLowerCase().includes(input)
                    )
                  );
                }}
              />
            </div>
          )}
      </div>
    </div>
  );
});
